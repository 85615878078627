<template>
  <div class="company">
    <CompanyAboutUs />
    <CompanyDescription id="company-desp" />
    <CompanyPartners />
    <GetStarted />
  </div>
</template>

<script>
import CompanyAboutUs from "../components/CompanyAboutUs.vue";
import CompanyDescription from "../components/CompanyDescription.vue";
import CompanyPartners from "../components/CompanyPartners.vue";
import GetStarted from "../components/GetStarted";

export default {
  name: "company",
  components: {
    CompanyAboutUs,
    CompanyDescription,
    CompanyPartners,
    GetStarted,
  },
  data() {
    return {
      title: "Tekkis - About Us",
      description:
        "Tekkis is a tech company that is making the internet safer for everyone. Keeping it secure right from the start, when onboarding users, up to completing online transactions.",
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: "description",
          content: this.description,
        },
        {
          property: "og:title",
          content: this.title,
        },
        { property: "og:site_name", content: "Tekkis" },
        { property: "og:type", content: "website" },
      ],
    };
  },

  methods: {
    updateMetadata() {
      // Update the data properties to change the metadata
      this.title = "description";
      this.description =
        "Tekkis is a tech company that is making the internet safer for everyone. Keeping it secure right from the start, when onboarding users, up to completing online transactions.";
    },
  },
};
</script>
